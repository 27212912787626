import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  format,
} from "date-fns";
import { Divider, Paper, Tooltip, Typography, alpha } from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { BiEdit, BiSolidRightArrow } from "react-icons/bi";
import NotesIcon from "@mui/icons-material/Notes";
import { PiMagicWandFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  getTransactionByParams,
  getTailwindColor,
  formatAmount,
  Highlighted,
} from "../../../../../Helper/data";
import ToolTipWrapper from "../../../../../components/TooltipWrapper";
import useSubscriptions from "../../../../../hooks/useSubscriptions";
import { Color, Constant, Fonts } from "../../../../../Helper";
import Translate from "../../../../../hooks/HOC/Translate";
import Icon from "../../../../../components/Icon";
import theme from "../../../../../theme";

const CellWrapper = styled("span")((props) => ({
  padding: "4px",
  margin: 0,
  position: "relative",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  "& #magic-icon:hover": {
    backgroundColor: `${theme?.palette?.primary?.main} !important`,
    "& svg": {
      color: `${theme?.palette?.color?.white} !important`,
    },
  },
  "& #note-icon:hover": {
    borderBottom: `20px solid ${theme?.palette?.color?.amber?.[400]} !important`,
    "& svg": {
      color: `${theme?.palette?.color.white} !important`,
    },
  },
  "&:hover": {
    border: `1px solid ${theme?.palette?.primary?.main}`,
    "& #magic-icon": {
      border: `1px solid ${theme?.palette?.primary?.main}`,
      display: "flex !important",
    },
    "& #note-icon": {
      display: `flex !important`,
    },
    "& #table_link_icon": {
      display: `flex !important`,
    },
    "& .inner-cell": {
      backgroundColor:
        !props?.cell?.isColumnStartCell && !props?.cell?.isHeader
          ? theme.palette.color.slate[50]
          : "transparent",
      border: `${
        !props?.cell?.isColumnStartCell
          ? `1px solid ${theme?.palette?.primary?.main} !important`
          : 0
      }`,
    },
    [`& ${`#${props?.$columnKey}-column`}`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
  },
  "& .inner-cell": {
    textAlign: "left",
    lineHeight: "normal",
    verticalAlign: "top",
    fontWeight: 500,
    fontSize: "8px",
    overflow: "hidden",
    fontFamily: Fonts.Text,
    borderStyle: " solid",
    color: "black",
    position: "absolute",
    width: "100%",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },

  "& input": {
    padding: "6px",
    zIndex: 10,
    fontWeight: 500,
    lineHeight: "normal",
    verticalAlign: "top",
    fontSize: "13px",
    overflow: "hidden",
    fontFamily: Fonts.Text,
    color: "black",
    position: "absolute",
    top: "1px",
    left: "1px",
    right: "1px",
    bottom: "1px",
    border: 0,
    textAlign: "right",
    width: `calc(100% - 2px)`,
    height: `calc(100% - 2px)`,
  },
}));
const regex = /^\d{0,8}(\.\d{0,2})?$/;
const expandedCellBorderColor = Color.tailwind.slate[400];
const defaultBorderColor = "#cacaca7d";
const Cell = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubscriptionValid] = useSubscriptions();

  const cellUuid = useRef(null);
  const timer = useRef(0);
  const prevent = useRef(false);
  const delay = useRef(400);
  const isEdited = useRef(false);
  const bgColor = useRef([]);

  const cell = props?.cell;
  const cellKey = `${cell?.rowId}-${cell?.cellId}`;

  // const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  // const highlightedScenarios = useSelector(
  //   (state) => state.boardSlice?.highlightedScenarios
  // );

  //states
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(cell?.item?.total?.planned);

  const isEmptyCell =
    cell?.item?.count?.bookedCount === 0 &&
    cell?.item?.count?.plannedCount === 0 &&
    cell?.item?.count?.openCount === 0 &&
    (cell?.item?.isCurrentMonth ? !cell?.item?.pastTotal : true);

  const clicked = (e) => {
    timer.current = setTimeout(() => {
      if (!prevent.current) {
        props?.onClick({ cellKey, cell, isEditing });
      }
      prevent.current = false;
    }, delay.current);
  };

  const doubleClicked = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      clearTimeout(timer.current);
      if (cell?.editable && !cell?.hasChildren) {
        prevent.current = true;
        props?.onDoubleClick({ cellKey, cell });

        if (!props?.commonFunctionRef?.current?.isTableFetching) {
          setValue(cell?.item?.total?.scenario_planned);
          setIsEditing(true);
        } else {
          enqueueSnackbar(t("please wait table is being updated"), {
            variant: "info",
            preventDuplicate: true,
          });
        }
      }
    }
  };

  const onChange = (e) => {
    const inputValue = e.target.value;

    if (regex?.test(inputValue)) {
      isEdited.current = true;
      setValue(inputValue);
    }
  };

  const onKeyPressOnInput = (e) => {
    if (e.key === "Enter") {
      hasNewValue(e.target.value);
    }
  };

  const onBlur = (e) => {
    hasNewValue(e.target.value);
  };

  const hasNewValue = (inputValue) => {
    if (regex?.test(inputValue) && isEdited.current) {
      isEdited.current = false;
      props?.onChangedValue(
        {
          rowId: cell?.rowId,
          columnId: cell?.cellId,
          cell: cell,
        },
        inputValue,
        cellUuid.current
      );
    }
    setIsEditing(false);
  };

  const onClickLink = (e) => {
    e.stopPropagation();
    navigate(cell?.item?.link, {
      state: {
        isOutflow: cell?.item?.uuid === "outflow",
        fromCategories: true,
      },
    });
  };

  const onMouseEnter = () => {
    if (cell?.rowId && cell?.isHover) {
      const elements = document.querySelectorAll(`#row-${cell?.rowId}`);
      bgColor.current = [];
      elements.forEach((element, index) => {
        bgColor.current.push(element.style.backgroundColor);
        element.style.backgroundColor =
          index === 0 && cell?.item?.income_expense_type === 1
            ? Color.tailwind.green[100]
            : index === 0 && cell?.item?.income_expense_type === 2
              ? Color.tailwind.red[100]
              : Color.tailwind.slate[100];
      });
    }
  };

  const onMouseLeave = () => {
    if (cell?.rowId && cell?.isHover) {
      const elements = document.querySelectorAll(`#row-${cell?.rowId}`);
      elements.forEach((element, index) => {
        element.style.backgroundColor = bgColor.current?.[index];
      });
    }
  };

  const onClickIcon = (e) => {
    props?.onClickArrow(e, { cellKey, cell, isEditing });
  };

  //lifecycle
  useEffect(() => {
    if (isEditing && !cellUuid.current) {
      cellUuid.current = cell?.item?.uuid;
      // getUuid();
    } else {
      cellUuid.current = null;
    }
  }, [isEditing]);

  const isParentExpanded =
    cell?.rowId !== "net_change" &&
    cell?.rowId !== "start_liquidity" &&
    cell?.rowId !== "end_liquidity" &&
    cell?.rowId !== "VAT_Imputation" &&
    cell?.rowId !== "vat" &&
    cell?.rowId !== "inflow" &&
    cell?.rowId !== "outflow" &&
    props?.expanded?.includes(cell?.rowId);

  const isChildExpanded =
    cell?.parentRowId !== "inflow" &&
    cell?.parentRowId !== "outflow" &&
    props?.expanded?.includes(cell?.parentRowId);

  const isExpanded = isParentExpanded || isChildExpanded;
  // const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
  const showNoteIcon =
    !isEditing &&
    !cell?.isColumnStartCell &&
    !cell?.isHeader &&
    !cell?.hasChildren &&
    !isEmptyCell &&
    cell?.rowId !== "VAT_Imputation" &&
    cell?.is_note_available;
  const showMagicIcon =
    cell?.item?.isCurrentMonth &&
    !cell?.hasChildren &&
    !cell?.isHeader &&
    cell?.rowId !== "uncategorizedInflow" &&
    cell?.rowId !== "uncategorizedOutflow" &&
    cell?.rowId !== "VAT_Imputation";
  const showPatternCell =
    (cell?.hasChildren && !cell?.isHeader) ||
    (cell?.pattern && !cell.isCurrentMonth);
  const showLateDot =
    Math.abs(cell?.item?.pastTotal) > 0 &&
    cell?.item?.isCurrentMonth &&
    !cell.isHeader;

  return (
    <CellWrapper
      id={`row-${cell?.rowId}`}
      $cellKey={cellKey}
      $columnKey={cell?.cellId}
      $selectedCell={props?.selectedCell}
      onClick={clicked}
      onDoubleClick={doubleClicked}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cell={cell}
      style={{
        paddingInline: cell?.isColumnStartCell ? "0px" : "12px",
        width: cell?.gridWidth,
        height: cell?.rowHeight,
        color:
          getTailwindColor(
            cell?.isExcludedCategory ? "slate" : cell?.color,
            cell?.isExcludedCategory ? 400 : 600
          ) || "inherit",

        borderLeftWidth:
          cell?.mergeCell && !cell?.isFirstCell
            ? 0
            : cell?.borderLeftWidth || "1px",
        borderRightWidth:
          cell?.isLastCell || cell?.isSticky
            ? cell?.borderRightWidth || "1px"
            : 0,
        borderTopWidth:
          cell.isFirstRowCell || isParentExpanded
            ? cell?.borderTopWidth || "1px"
            : 0,
        borderBottomWidth: cell?.borderBottomWidth || "1px",

        borderLeftStyle: isExpanded && cell?.isFirstCell ? "dashed" : "solid",
        borderRightStyle: isExpanded && cell?.isLastCell ? "dashed" : "solid",
        borderTopStyle: isParentExpanded ? "dashed" : "solid",
        borderBottomStyle:
          isChildExpanded && cell?.isLastCategoryChildCell ? "dashed" : "solid",

        borderLeftColor:
          isExpanded && cell?.isFirstCell
            ? expandedCellBorderColor
            : cell?.borderColor || defaultBorderColor,
        borderRightColor:
          isExpanded && cell?.isLastCell
            ? expandedCellBorderColor
            : cell?.borderColor || defaultBorderColor,
        borderTopColor: isParentExpanded
          ? expandedCellBorderColor
          : cell?.borderColor || defaultBorderColor,
        borderBottomColor:
          isChildExpanded && cell?.isLastCategoryChildCell
            ? expandedCellBorderColor
            : cell?.borderColor || defaultBorderColor,

        borderTopLeftRadius:
          cell?.isFirstRowCell && cell?.isFirstCell ? props?.borderRadius : 0,
        borderTopRightRadius:
          cell?.isFirstRowCell && cell?.isLastCell ? props?.borderRadius : 0,
        borderBottomLeftRadius:
          props?.isLastRowCell && cell?.isFirstCell ? props?.borderRadius : 0,
        borderBottomRightRadius:
          props?.isLastRowCell && cell?.isLastCell ? props?.borderRadius : 0,

        fontFamily: Fonts.Text,
        textTransform: cell?.textTransform || "initial",
        fontStyle: cell?.isExcludedCategory
          ? "italic"
          : cell?.fontStyle || "normal",
        fontSize:
          cell?.fontSize ||
          (parseFloat(cell?.item?.value)?.toFixed(0)?.toString()?.length >= 7
            ? "10px"
            : "12px"),
        fontWeight: cell?.fontWeight || 500,
        backgroundColor: cell?.backgroundColor,
        position: cell?.isSticky ? "sticky" : "relative",
        zIndex: cell?.isSticky ? 15 : 0,
        left: 0,
        cursor: cell?.clickable || cell?.showCursor ? "pointer" : "default",
        overflow: "auto",
      }}
      role="presentation"
    >
      <span
        className="inner-cell"
        style={{
          zIndex: 10,
          borderRadius: "inherit",
          borderWidth: props?.selectedCell === cellKey ? "2px" : "0",
          borderColor: isEditing
            ? Color.tailwind.purple[700]
            : Color.tailwind.purple[400],
        }}
      ></span>
      {showMagicIcon ? (
        <ToolTipWrapper title={<TooltipView cell={cell} />}>
          <div
            onClick={(e) => props?.onClickMagicIcon(e, cell)}
            id="magic-icon"
            style={{
              display: "none",
              backgroundColor: Color.white,
              position: "absolute",
              left: "4.5px",
              padding: "2.5px",
              paddingLeft: "2px",
              borderRadius: 4,
              zIndex: 20,
            }}
          >
            <Icon
              icon={<PiMagicWandFill />}
              fontSize={"1.3rem"}
              color={Color.tailwind.slate[500]}
              style={{ cursor: "pointer" }}
            ></Icon>
          </div>
        </ToolTipWrapper>
      ) : null}
      {showNoteIcon ? (
        <ToolTipWrapper title={<NoteTooltipView cell={cell} />}>
          <div
            id="note-icon"
            style={{
              borderBottom: `20px solid ${getTailwindColor(
                "amber",
                cell?.is_note_highlighted
                  ? 100
                  : cell?.is_note_user_edited
                    ? 300
                    : 200
              )}`,
              borderLeft: `20px solid transparent`,
              width: 0,
              height: 0,
              display: cell?.is_note_highlighted ? "flex" : "none",
              position: "absolute",
              right: "2px",
              bottom: "2px",
              zIndex: 20,
              border: "none !important",
            }}
          ></div>
        </ToolTipWrapper>
      ) : null}
      {showPatternCell ? (
        <svg
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <defs>
            <pattern
              id={`Pattern-${cellKey}`}
              width="8"
              height="4"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect
                width="4"
                height="4"
                fill={getTailwindColor(
                  cell?.patternColor || "slate",
                  cell?.isColumnStartCell ? 100 : 50
                )}
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill={`url(#Pattern-${cellKey})`} />
        </svg>
      ) : null}
      {showLateDot ? (
        <Tooltip placement="top" title={t("Late Transactions")}>
          <div
            style={{
              width: "6px",
              height: "6px",
              position: "absolute",
              borderRadius: 8,
              top: 3,
              right: 3,
              zIndex: 10,
              backgroundColor: Color.tailwind.red[500],
            }}
          ></div>
        </Tooltip>
      ) : null}
      {isEditing ? (
        <input
          inputMode={cell?.item?.type ? cell?.item?.type : "text"}
          onBlur={onBlur}
          onKeyDown={onKeyPressOnInput}
          value={!value || value === 0 ? "" : value}
          onChange={onChange}
          autoFocus
          style={{
            outline: `2px solid ${Color.tailwind.purple[700]}`,
          }}
        />
      ) : (
        <Tooltip title={cell?.item?.value2 || ""} placement="top">
          <span
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "inherit",
              fontStyle: "inherit",
              display: "flex",
              alignItems: "center",
              width: "100%",
              zIndex: 10,
              paddingLeft: cell?.isColumnStartCell
                ? (cell?.depth || 0) * 26
                : 0,
            }}
          >
            {cell?.isColumnStartCell ? (
              <span
                style={{
                  width: "16%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {cell?.item?.icon ? (
                  <div
                    style={{
                      display: "contents",
                    }}
                    onClick={onClickIcon}
                  >
                    {cell?.item?.icon}
                  </div>
                ) : cell?.hasChildren ? (
                  <Icon
                    key={cell?.rowId}
                    onClick={onClickIcon}
                    icon={<BiSolidRightArrow />}
                    color={
                      Color.tailwind.slate[
                        props?.expanded?.includes(cell?.rowId) ? 700 : 500
                      ]
                    }
                    fontSize={"12px"}
                    style={{
                      transform: props?.expanded?.includes(cell?.rowId)
                        ? "rotate(90deg)"
                        : "none",
                      transition: "transform 0.3s ease-in-out",
                      zIndex: 15,
                    }}
                  ></Icon>
                ) : null}
              </span>
            ) : null}
            <span
              onClick={
                cell?.item?.value === "Outflow" ||
                cell?.item?.value === "Inflow"
                  ? onClickIcon
                  : undefined
              }
              style={{
                width: cell?.isColumnStartCell
                  ? `calc(86% - ${cell?.isHeader ? 16 : 0}% - ${(cell?.item?.postIcon?.length || 0) * 10}%)`
                  : "100%",

                display: "block",
                textAlign: cell?.isColumnStartCell ? "left" : "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "inherit",
                fontFamily: "inherit",
                fontStyle: "inherit",
                color: "inherit",
              }}
            >
              {isEmptyCell ? null : cell?.hasOwnProperty("customCellRender") ? (
                cell?.customCellRender()
              ) : cell?.customCell ? (
                <CustomCell
                  {...{
                    cell,
                    cellKey,
                    uuid: cellUuid.current,
                    ...props,
                  }}
                />
              ) : cell?.isColumnStartCell && cell?.item?.searchText ? (
                <Highlighted
                  text={cell?.item?.value}
                  highlight={cell?.item?.searchText}
                  key={`cell-${cell?.rowId}-${cell?.cellId}`}
                />
              ) : (
                cell?.item?.value
              )}
            </span>
            {cell?.item?.postIcon?.map((item, index) => (
              <Icon
                key={`${item?.rowId} - ${index}`}
                tooltip={item?.tooltip}
                icon={item?.icon}
                color={theme.palette.color.slate[500]}
                fontSize={"12px"}
                onClick={onClickLink}
                style={{
                  width: "10%",
                  marginRight: "0.25rem",
                  cursor: "pointer",
                  transition: "transform 0.3s ease-in-out",
                }}
              ></Icon>
            ))}
            {cell?.item?.link ? (
              <Icon
                id="table_link_icon"
                key={"table_link_icon" - cell?.rowId}
                icon={<BiEdit />}
                color={
                  cell?.rowId === "inflow"
                    ? Color.tailwind.green[500]
                    : Color.tailwind.red[500]
                }
                fontSize={"18px"}
                onClick={onClickLink}
                style={{
                  display: "none",
                  // position: "absolute",
                  // right: 10,
                  marginRight: "0.5rem",
                  cursor: "pointer",
                  transition: "transform 0.3s ease-in-out",
                }}
              ></Icon>
            ) : null}
          </span>
        </Tooltip>
      )}
    </CellWrapper>
  );
};

export default Cell;
const CustomCell = (props) => {
  const { cell } = props;

  if (!props) {
    return null;
  }

  return (
    <Tooltip placement="top" title={cell?.item?.value2 || ""}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          position: "relative",
          paddingRight: "4px",
          fontSize: "inherit",
          color: cell?.showFontColor
            ? cell?.item?.value >= 0
              ? Color.tailwind.green[500]
              : Color.tailwind.red[500]
            : "inherit",
        }}
      >
        {cell?.item?.type === "numeric"
          ? formatAmount({
              amount: cell?.item?.value,
              hidePrefix: true,
            })
          : null}

        <div
          style={{
            minWidth: "44px",
            width: "fit-content",
            height: "20px",
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {cell?.item?.value !== 0 &&
          !cell?.isHeader &&
          !cell?.isColumnStartCell &&
          cell.item?.count?.plannedCount !== 0 &&
          !cell?.hidePercentage ? (
            <PercentageChangeChip
              item={cell?.item}
              type={cell?.item?.income_expense_type}
            />
          ) : null}
        </div>
      </div>
    </Tooltip>
  );
};

const PercentageChangeChip = ({ item, type = 1 }) => {
  let color = "slate";
  let bgShade = 100;
  let colorShade = 500;
  const percentageChange = item?.percentageChange;
  let change =
    !isNaN(percentageChange) && percentageChange ? Number(percentageChange) : 0;

  if (item?.value > 0 || (item?.value === 0 && type === 1)) {
    if (item?.compareValue >= item?.plannedValue || change >= 99) {
      color = "emerald";
      bgShade = 100;
      colorShade = 600;
    }
    if (item?.compareValue < item?.plannedValue && change < 99) {
      if (item?.isFutureMonth) {
        color = "slate";
        bgShade = 100;
        colorShade = 500;
      } else {
        color = "orange";
        bgShade = 100;
        colorShade = 600;
      }
    }
  }
  if (item?.value < 0 || (item?.value === 0 && type === 2)) {
    if (item?.compareValue >= item?.plannedValue || change <= 101) {
      color = "lime";
      bgShade = 100;
      colorShade = 600;
    }
    if (item?.compareValue < item?.plannedValue && change > 101) {
      if (item?.isFutureMonth) {
        color = "slate";
        bgShade = 100;
        colorShade = 500;
      } else {
        color = "red";
        bgShade = 100;
        colorShade = 600;
      }
    }
  }
  if ((item?.value < 0 && type === 1) || (item?.value > 0 && type === 2)) {
    change = -Math.abs(change);
  }
  if (percentageChange === 0) {
    color = "slate";
    bgShade = 100;
    colorShade = 500;
  }

  const chipStyle = {
    borderRadius: 4,
    backgroundColor: getTailwindColor(color, bgShade),
    color: getTailwindColor(color, colorShade),
    width: "100%",
    height: "100%",
    fontSize: change?.toString()?.length > 3 ? "9px" : "10px",
    fontFamily: Fonts.Text,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingInline: "4px",
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return <div style={chipStyle}>{change}%</div>;
};

const TooltipView = forwardRef(({ cell }, _ref) => {
  const { t } = useTranslation();

  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const internal_data_source = useSelector(
    (state) => state.boardSlice?.dataSetData?.internal_data_source
  );
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );

  const dataSetData = {
    uuid: uuid,
    internal_data_source: internal_data_source,
  };
  const [tooltip, setTooltip] = useState(""); //auto-forecast-cell-tooltip
  const [background, setBackground] = useState(Color.tailwind.slate[600]);

  useEffect(() => {
    generateTooltip();
  }, []);

  const generateTooltip = async () => {
    const category =
      cell?.rowId === "uncategorizedInflow" ||
      cell?.rowId === "uncategorizedOutflow"
        ? null
        : cell?.rowId;
    // dispatch(setLoading(true));

    let response = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      category: category ? [category] : [],
      scenario: [highlightedScenarios?.[0]],
      startDate: format(addMonths(new Date(cell?.item?.date), 1), "yyyy-MM-dd"),
      categorized: category ? true : false,
      is_recurred: true,
      income_expense_type: cell?.item?.income_expense_type,
    });
    let recurring_rules_list = response
      ? [...new Set(response?.results?.map((o1) => o1.recurring_rule))]
      : [];

    if (recurring_rules_list?.length === 0) {
      setBackground(theme?.palette?.primary?.main);
      setTooltip("auto-forecast-simple-tooltip");
    }
    if (recurring_rules_list?.length === 1) {
      setBackground(theme?.palette?.primary?.main);
      setTooltip("auto-forecast-one-seq-tooltip");
    }
    if (recurring_rules_list?.length > 1) {
      setBackground(Color.tailwind.slate[400]);
      setTooltip("auto-forecast-multiple-seq-tooltip");
    }
  };

  return (
    <span
      ref={_ref}
      style={{
        backgroundColor: background,
        color: Color.white,
        fontSize: "0.8rem",
        fontFamily: Fonts.Text,
        padding: "4px 8px",
        borderRadius: 4,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Translate i18nkey={tooltip ? t(tooltip) : `${t("Loading")}..`} />
    </span>
  );
});

const NoteTooltipView = forwardRef(({ cell }, _ref) => {
  const { t } = useTranslation();
  const highlightedNotesFound = useRef(false);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );

  const dataSetData = {
    uuid: uuid,
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    generateTooltip();
  }, []);

  const generateTooltip = async () => {
    setLoading(true);
    const category =
      cell?.rowId === "uncategorizedInflow" ||
      cell?.rowId === "uncategorizedOutflow"
        ? null
        : cell?.rowId;

    let finalResponse = [];
    let params = {
      dataset: dataSetData?.uuid,
      category: category ? [category] : [],
      scenario: [highlightedScenarios?.[0]],
      categorized: category ? true : false,
      income_expense_type: cell?.item?.income_expense_type,
    };
    const response = await getTransactionByParams({
      ...params,
      from_payment_date: startOfMonth(new Date(cell?.item?.date)),
      to_payment_date: endOfMonth(new Date(cell?.item?.date)),
    });
    if (cell?.item?.isCurrentMonth) {
      let state = [];
      Constant?.openPositionsStates?.forEach((title) => {
        state.push(stateByTitle?.[title]?.[0]?.uuid);
      });
      params.state = state;
      const LateResponse = await getTransactionByParams({
        ...params,
        from_payment_date: startOfMonth(subMonths(new Date(), 6)),
        to_payment_date: endOfMonth(subMonths(new Date(cell?.item?.date), 1)),
      });
      finalResponse = [
        ...(response?.results || []),
        ...(LateResponse?.results || []),
      ];
    } else {
      finalResponse = [...(response?.results || [])];
    }
    const filterData = finalResponse
      ?.filter((o1) => o1?.note?.trim() !== "")
      ?.sort((a, b) => b.gross_value - a.gross_value);

    const highlightedData = filterData?.filter((o1) => o1?.is_note_highlighted);
    const restData = filterData?.filter((o1) => !o1?.is_note_highlighted);
    highlightedNotesFound.current = highlightedData?.length > 0;
    setTimeout(() => {
      setData([...highlightedData, ...restData]);
      setLoading(false);
    }, 0);
  };

  return (
    <Paper
      elevation={1}
      component={"div"}
      ref={_ref}
      sx={{
        backgroundColor: Color.white,
        color: theme.palette.color.slate[700],
        fontSize: "0.8rem",
        padding: "1rem",
        borderRadius: 4,
        width: "100%",
        minWidth: "15rem",
        maxHeight: "25rem",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        ...theme.thinScrollBar,
      }}
    >
      {loading ? (
        `${t("Loading")}..`
      ) : data?.length > 0 ? (
        <div style={{ width: "100%" }}>
          <p
            style={{
              width: "100%",
              fontFamily: Fonts.Text,
              fontSize: "1rem",
              fontWeight: 600,
              color: Color.tailwind.slate[700],
              display: "flex",
              alignItems: "center",
              marginTop: 0,
              marginBottom: "0rem",
            }}
          >
            {" "}
            <NotesIcon
              sx={{
                color: Color.theme.blueGrey[700],
                mr: "0.5rem",
                fontSize: "1.4rem",
              }}
            />
            {t("table_cell_notes_tooltip_header")}
          </p>
          <Divider
            sx={{
              opacity: 0.6,
              my: "0.5rem",
            }}
          />

          {highlightedNotesFound.current ? (
            <Typography variant="body2" color="color.description" gutterBottom>
              {t("table_cell_tooltip_highlighted_notes_header")}
            </Typography>
          ) : null}
          {data?.map((item, index) => {
            const isSectionEnd =
              highlightedNotesFound.current &&
              item?.is_note_highlighted &&
              !data?.[index + 1]?.is_note_highlighted;
            return (
              <div
                key={item?.uuid}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <p
                    style={{
                      fontFamily: Fonts.Text,
                      fontSize: "0.7rem",
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                      marginBlock: "0.25rem",
                      marginRight: "0.5rem",
                      color:
                        item?.gross_value < 0
                          ? theme.palette.color.red[500]
                          : theme.palette.color.green[500],
                      backgroundColor:
                        item?.gross_value < 0
                          ? theme.palette.color.red[50]
                          : theme.palette.color.green[50],
                      padding: "0.25rem",
                      paddingInline: "0.5rem",
                      borderRadius: theme.borderRadius.borderRadiusMD,
                    }}
                  >
                    {formatAmount({
                      amount: item?.gross_value,
                    })}
                  </p>
                  <p
                    style={{
                      fontFamily: Fonts.Text,
                      fontSize: "0.85rem",
                      fontWeight: 500,
                      color: Color.tailwind.slate[700],
                      display: "flex",
                      alignItems: "center",
                      marginBlock: "0.25rem",
                    }}
                  >
                    {item?.title}{" "}
                    {/* {item?.is_note_user_edited ? (
                    <PersonIcon sx={{ fontSize: "1rem", ml: "0.25rem" }} />
                  ) : null} */}
                  </p>
                </div>

                <p
                  style={{
                    fontFamily: Fonts.Text,
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    color: Color.tailwind.slate[500],
                    marginBlock: "0.25rem",
                  }}
                >
                  {item?.note}
                </p>
                {item?.is_note_highlighted ? (
                  <div
                    style={{
                      borderBottom: `14px solid ${getTailwindColor(
                        "amber",
                        item?.is_note_highlighted
                          ? 100
                          : item?.is_note_user_edited
                            ? 300
                            : 200
                      )}`,
                      borderLeft: `14px solid transparent`,
                      width: 0,
                      height: 0,
                      position: "absolute",
                      right: "2px",
                      bottom: isSectionEnd ? "4rem" : "1rem",
                      zIndex: 20,
                      border: "none !important",
                    }}
                  />
                ) : null}
                {index === data?.length - 1 ? null : (
                  <Divider
                    sx={{
                      border: `1px ${isSectionEnd ? "solid" : "dashed"} ${Color.tailwind.slate[200]}`,
                      my: isSectionEnd ? "2rem" : "0.5rem",
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : (
        t("No notes found")
      )}
    </Paper>
  );
});
